var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ModalActive),expression:"!ModalActive"}]},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12","lg":"12","xl":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
							content: _vm.$t('label.return'),
							placement: 'top',
						}),expression:"{\n\t\t\t\t\t\t\tcontent: $t('label.return'),\n\t\t\t\t\t\t\tplacement: 'top',\n\t\t\t\t\t\t}"}],staticClass:"mr-2",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.Reset()}}},[_c('CIcon',{attrs:{"name":"cil-chevron-left-alt"}})],1),_c('h6',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(`${_vm.$t('label.nuevo')} ${_vm.$t('label.recognition')}`)+" ")])],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":""},scopedSlots:_vm._u([{key:"button",fn:function({item}){return [_c('td',{staticClass:"text-center"},[(item.Position)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: `${_vm.$t('label.nuevo')} ${_vm.$t('label.containerPositioning')}`,
                    placement: 'top-start'
                  }),expression:"{\n                    content: `${$t('label.nuevo')} ${$t('label.containerPositioning')}`,\n                    placement: 'top-start'\n                  }"}],staticClass:"mr-1",attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleAdd(item)}}},[_c('CIcon',{attrs:{"name":"machinery"}})],1):_vm._e()],1)]}}])})],1)],1),_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12","lg":"12"}},[(!_vm.formatedItems.some((item) => item.Position==true))?_c('CButton',{staticClass:"mr-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.Continue()}}},[_c('CIcon',{attrs:{"name":"continue"}}),_vm._v("  "+_vm._s(_vm.$t('label.Continue'))+" ")],1):_vm._e()],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ModalActive),expression:"ModalActive"}]},[_c('PositioningRegister',{attrs:{"Active":_vm.ModalActive,"Recognition":true,"PositionItem":_vm.PositionItem},on:{"Close":function($event){(_vm.ModalActive=false, _vm.PositionItem={})},"Update-list":_vm.UpdateData}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }