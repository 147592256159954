<template>
  <div>
    <div v-show="!ModalActive">
      <CRow class="mb-3">
				<CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
					<CButton
						color="edit"
						class="mr-2"
						v-c-tooltip="{
							content: $t('label.return'),
							placement: 'top',
						}"
						size="sm"
						@click="Reset()"
					>
						<CIcon name="cil-chevron-left-alt" />
					</CButton>
					<h6 class="mt-2">
						{{ `${$t('label.nuevo')} ${$t('label.recognition')}` }}
					</h6>
				</CCol>
			</CRow>
      <CRow>
        <CCol sm="12" xl="12">
          <dataTableExtended
              class="align-center-row-datatable"
              hover
              sorter
              small
              details
              column-filter
              :items="formatedItems"
              :fields="fields"
              :noItemsView="tableText.noItemsViewText"
              :items-per-page="tableText.itemsPerPage"
              pagination
          >
            <template #button="{item}">
              <td class="text-center">
                <CButton
                  v-if="item.Position"
                  color="watch"
                  square
                  size="sm"
                  class="mr-1"
                  v-c-tooltip="{
                    content: `${$t('label.nuevo')} ${$t('label.containerPositioning')}`,
                    placement: 'top-start'
                  }"
                  @click="toggleAdd(item)"
                >
                  <CIcon name="machinery" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" lg="12" class="d-flex justify-content-end">
          <CButton
            v-if="!formatedItems.some((item) => item.Position==true)"
            color="watch"
            size="sm"
            class="mr-1"
            @click="Continue()"
          >
            <CIcon name="continue" />&nbsp; {{$t('label.Continue')}}
          </CButton>
        </CCol>
      </CRow>
    </div>
    <div v-show="ModalActive">
      <PositioningRegister
        :Active="ModalActive"
        :Recognition="true"
        :PositionItem="PositionItem"
        @Close="(ModalActive=false, PositionItem={})"
        @Update-list="UpdateData"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';
import PositioningRegister from '../../movements/container-positioning/positioning-register';

//DATA-------------------------
function data() {
  return {
    ModalActive: false,
    Position: 0,
    showIndex: 0,
    PositionItem: {},
    PositionsJson: [],
  }
}

async function getYardCargo(Update) {
  await this.$http.get('YardCargo-by-Id', { YardCargoId: Update.YardCargoId, YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        if (process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION == List[0].YardCargoStatus.toUpperCase()) {
          this.$store.state.yardManagement.globalSelectContainer = 
            this.$store.state.yardManagement.globalSelectContainer.map(Select=>{
              return Select.CargoId == this.CargoId ? List[0] : Select;
            });
        }
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function UpdateData(Update) {
  this.$store.state.yardManagement.loading = true;
  await this.getYardCargo(Update);
  this.Position++;
  this.PositionsJson[Update.IdX-1].Status = true;
  this.$store.state.yardManagement.loading = false;
}

function toggleAdd(item) {
  this.PositionItem = item;
  this.ModalActive = true;
}

function Continue() {
  this.$emit("Continue");
}

function Reset() {
	this.$emit("Close");
}

//computed
function formatedItems() {
  return this.PositionsJson.map((item, index) => {
    return {
      ...item,
      Position: this.Position >= index && !item.Status ? true : false,
      _classes: item.Status ? 'Correct-Row' : 'Error-Row',
      SlotCode: item.YardSlotJson&&item.YardSlotJson.length!=0 ? 
        item.YardSlotJson.map(YardSlot => YardSlot.SlotCode.trim()).join(', ') : '',
    };
  })
}

function fields(){
  return [
    { key: 'button', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
    { key: 'IdX', label: '#', _style: 'width:3%; text-align:center', _classes: 'center-cell text-center', filter: false },
    { key: 'ContainerCode', label: this.$t('label.container'),  _classes: 'text-uppercase text-center', _style: 'width:25%; text-align:center;', sorter: true, filter: true},
    { key: 'Size', label: `${this.$t('label.size')}`, _style:'width:22%', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'HeightCode', label: `${this.$t('label.level')}`, _style:'width:22%', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'SlotCode', label: `${this.$t('label.positionContainer')}`, _style:'width:26%', _classes: 'text-uppercase text-center', sorter: true, filter: true},
  ];
 }

export default {
  name: 'recognition-positions',
  data,
  components:{
    PositioningRegister,
  },
  mixins: [GeneralMixin],
  methods: {
    getYardCargo,
    UpdateData,
    toggleAdd,
    Continue,
    Reset,
  },
  computed: {
    fields,
    formatedItems,
    ...mapState({
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
      YardId: state => state.yardManagement.yardData.YardId,
      GeneralList: state => state.yardManagement.GeneralList,
      dataContainer: state => state.yardManagement.dataContainer,
      dropContainerMovement: state => state.yardManagement.dropContainerMovement,
    })
  },
  mounted: function (){
    this.PositionsJson = this.dataContainer.ReconPositionsJson.filter(item =>
      item.YardCargoId != this.dataContainer.YardCargoId
    );
  },
  watch: {
    
  }
};
</script>