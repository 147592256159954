var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==0),expression:"showIndex==0"}]},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12","lg":"12","xl":"12"}},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.RecognitionList')}`)+" ")],1)])],1),(_vm.ValidateReception)?_c('CRow',[_c('CCol',{staticClass:"align-items-end mb-3",attrs:{"sm":"12","lg":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.recognition'),
            placement: 'top-end'
          }),expression:"{\n            content: $t('label.recognition'),\n            placement: 'top-end'\n          }"}],staticClass:"d-flex align-items-center ml-auto",attrs:{"shape":"square","color":"add"},on:{"click":function($event){return _vm.toggleAdd({}, false)}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)],1):_vm._e(),_c('CRow',[_c('CCol',{attrs:{"sm":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":""},scopedSlots:_vm._u([{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"button",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: `${_vm.$t('label.edit')} ${_vm.$t('label.recognition')}`,
                  placement: 'top-start'
                }),expression:"{\n                  content: `${$t('label.edit')} ${$t('label.recognition')}`,\n                  placement: 'top-start'\n                }"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleAdd(item, true)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.Seals'),
                  placement: 'top-start'
                }),expression:"{\n                  content: $t('label.Seals'),\n                  placement: 'top-start'\n                }"}],staticStyle:{"background-color":"#d6d6d7"},attrs:{"square":"","size":"sm"},on:{"click":function($event){return _vm.viewDetail(item)}}},[_c('CIcon',{attrs:{"name":"bottomSeals"}})],1)],1)]}},{key:"details",fn:function({item}){return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"small":"","items":_vm.formatedSeal(item.SealJson),"fields":_vm.level2Fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage},scopedSlots:_vm._u([{key:"button",fn:function({item}){return [_c('td',{staticClass:"text-center"},[(item.SealDocumentJson.length!=0&&item.Seal)?_c('CButton',{staticClass:"mr-1",attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.ModalImagen(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1):_vm._e()],1)]}}],null,true)})],1)],1)]}}])})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==1),expression:"showIndex==1"}]},[_c('RecognitionRegister',{attrs:{"Active":_vm.showIndex==1,"isEdit":_vm.isEdit,"RecognitionItem":_vm.RecognitionItem},on:{"Close":function($event){(_vm.showIndex=0, _vm.RecognitionItem={}, _vm.isEdit=false)},"Update-list":_vm.UpdateData}})],1),(_vm.showIndex==2)?_c('div',[(_vm.showIndex==2)?_c('RecognitionPositions',{on:{"Close":function($event){(_vm.showIndex=0)},"Continue":function($event){return _vm.toggleAdd({}, false)}}}):_vm._e()],1):_vm._e(),_c('ModalSealImages',{attrs:{"modal":_vm.ModalSeal,"labelModal":_vm.labelModalImages,"SealImages":_vm.SealImages},on:{"Close":function($event){(_vm.ModalSeal=false, _vm.labelModalImages='', _vm.SealImages=[])}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }